<template>
  <div class="main-content">
    <avue-crud
      ref="crud"
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-input
                  v-model="searchData.levelName"
                  placeholder="请输入等级名称"
                  clearable
                  maxlength="50"
                ></el-input>
              </div>
            </div>

            <el-button
              size="medium"
              type="primary"
              @click="handleSearch"
              style="margin-bottom: 10px"
              class="marginLeft10"
              >搜索</el-button
            >
            <el-button
              size="medium"
              type="info"
              @click="handleReset"
              style="margin-bottom: 10px"
              class="marginLeft10 marginRight10"
              >重置</el-button
            >
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="primary" @click="handleCreate"
                >新增</el-button
              >
            </div>
          </div>
        </div>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" @click="handleEdit(row)"
          >编辑</el-button
        >
        <el-button
          type="text"
          size="mini"
          @click="handleDelete(row)"
          style="color: #ff001f !important"
          >删除</el-button
        >
        <el-button type="text" size="mini" @click="jump(row)"
          >学习计划</el-button
        >
      </template>
    </avue-crud>
    <el-dialog
      v-if="dialogVisible"
      :title="`${form.id ? '编辑' : '新建'}`"
      :visible.sync="dialogVisible"
      width="650px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <avue-form ref="form" v-model="form" :option="formOption">
        <template slot="sort">
          <el-input-number
            v-model="form.sort"
            controls-position="right"
            :min="1"
            :max="99999"
            style="width:150px;"
            :disabled="sortShow"
            placeholder="输入等级排序"
          >
          </el-input-number>
          <span style="color: #ddd;margin-left: 15px;">数值越大排序越高</span>
        </template>
        <template slot="menuForm">
          <el-button @click="handleClose">取消</el-button>
          <el-button
            type="primary"
            :loading="showLoadingForm"
            @click="submit(form)"
            >确定</el-button
          >
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  studentUserLevelPage,
  studentsStudentAddLevel,
  studentGetLevelView,
  studentUpdateLevel,
  gatewayDelete,
} from "@/api/user/level";
import dayjs from "dayjs";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import qs from "qs";
// import { MChannel, OChannel, EChannel } from "@/enums/beCurrent/EBeCurrent";
export default {
  name: "index",
  components: {
    FormImgUpload,
  },
  data() {
    return {
      // MChannel: MChannel,
      // OChannel: OChannel,
      // EChannel: EChannel,
      searchData: {},
      page: {
        total: 0,
        currentPage: this.$route.query.newPage || 1,
      },
      tableData: [],
      showLoading: false,
      showLoadingForm: false,
      dialogVisible: false,
      option: {
        title: "",
        titleSize: "h3",
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        selection: false,
        reserveSelection: true,
        selectionFixed: true,
        tip: false,
        column: [
          {
            label: "id",
            prop: "id",
          },
          {
            label: "学生等级名称",
            prop: "levelName",
          },
          {
            label: "等级排序",
            prop: "sort",
          },
        ],
      },

      dialogVisible: false,
      form: {
        id: "",
      },
      formOption: {
        submitBtn: false,
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "120",
        row: true,
        column: [
          {
            label: "学生等级名称",
            prop: "levelName",
            maxlength: 10,
            span: 16,
            row: true,
            showWordLimit: true,
            placeholder: "输入学生等级名称",
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            label: "等级排序",
            prop: "sort",
            type: "number",
            maxRows: 99999,
            minRows: 1,
            span: 18,
            row: true,
            append: "字",
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      cityList: [], //城市选择列表
      visiblePassWord: false, //密码弹窗
      sortShow: false,
    };
  },
  created() {
    // this.getCity();
  },
  methods: {
    onLoad() {
      this.showLoading = true;
      const searchData = {
        ...this.searchData,
      };
      if (this.searchData.createTime) {
        searchData.visitTimeStart = dayjs(this.searchData.createTime[0]).format(
          "YYYY-MM-DD 00:00:00"
        );
        searchData.visitTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      delete searchData.createTime;
      studentUserLevelPage({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      this.searchData = {
        state: "",
        name: "",
        createTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 添加
    handleCreate() {
      this.form = {};
      this.sortShow = false;
      this.dialogVisible = true;
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    //编辑
    async handleEdit(row) {
      this.sortShow = false;
      this.form.id = row.id;
      this.dialogVisible = true;
      this.form = { ...row };
    },

    // 删除
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        gatewayDelete(row.id).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.$refs.crud.toggleSelection();
            this.onLoad();
          }
        });
      });
    },

    // 提交
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          if (this.showLoadingForm) {
            return;
          }
          this.showLoadingForm = true;
          console.log(form, "formformform");
          // return
          if (form.id) {
            studentUpdateLevel({
              ...form,
            })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);
                  this.onLoad();
                  this.showLoadingForm = false;
                } else {
                  // this.$message.error(res.msg);
                  this.showLoadingForm = false;
                  done();
                }
              })
              .catch(() => (this.showLoadingForm = false));
          } else {
            studentsStudentAddLevel({
              ...form,
            })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);
                  this.onLoad();
                  this.showLoadingForm = false;
                } else {
                  // this.$message.error(res.msg);
                  this.showLoadingForm = false;
                  done();
                }
              })
              .catch(() => (this.showLoadingForm = false));
          }
          // this.form.type = 1;
          // done()
        }
      });
    },
    //输入
    salaryDianChange(e, t) {
      const value = e.target.value;
      this.form[t] = value;
    },

    recert() {
      this.visiblePassWord = false;
    },
    //详情
    jump(row) {
      this.$router.push({
        path: "/user/plan",
        query: {
          id: row.id,
          newPage: this.page.currentPage,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.table {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table_item {
  margin-bottom: 10px;
  width: 30%;
  margin-right: 10px;
  text-align: center;
  font-size: 14px;
  display: flex;
  align-items: center;
  .table_label {
    width: 100px;
  }
}
.table_input {
  margin-right: 10px;
}
.table_add {
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
}
</style>
